<template>
  <div class="timeTrackingTable">
    <DataTable
      class="p-datatable-sm"
      :value="timeTrackings"
      :loading="isLoading"
      editMode="cell"
      :rowClass="rowClass"
      :rowHover="true"
      dataKey="number"
      :lazy="true"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="filterName"
      filterDisplay="row"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      :totalRecords="getTimeTrackingCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="'Anzeigen ' + '{first} bis {last} von {totalRecords} Einträgen'"
      :rowsPerPageOptions="[50, 100, 200]"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <div class="mt-2">
              <span
                v-if="
                  tableState.filters.startTime &&
                    tableState.filters.stopTime &&
                    getTimeTrackingCount &&
                    getTimeTrackingTotalHours
                "
              >
                Vom {{ $date(tableState.filters.startTime.value) }} bis zum
                {{ $date(tableState.filters.stopTime.value) }} wurden bei
                {{ getTimeTrackingCount }} Zeiterfassungen insgesamt
                {{ formatDuration(getTimeTrackingTotalHours) }} Arbeitsstunden geleistet.
              </span>
              <span v-else>
                Zeitraum oder Daten nicht vollständig definiert.
              </span>
            </div>
          </div>
          <div class="table-header-right">
            <ButtonWait
              :startCallback="startTimeTrackingExportBuild"
              :pollCallback="fetchExportBuildState"
              class="pull-right"
            >
              <template v-slot:buttonText>
                {{ $t('generate_time_tracking_list') }}
              </template>
            </ButtonWait>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>
      <Column
        field="project.number"
        filterField="projectNumber"
        sortable
        :header="$t('project') + ' #'"
        :styles="{ width: '7%' }"
        :headerStyle="{ width: '60px' }"
        headerClass="center"
        className="center"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data && slotProps.data.project && slotProps.data.project.number"
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.project.number },
            }"
            >{{ slotProps.data.project.number.toString().padStart(4, '0') }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
          <span v-else></span>
        </template>
      </Column>

      <Column
        field="createdAt"
        :header="$t('day')"
        sortable
        :headerStyle="{ width: '100px' }"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.createdAt">
            {{ formatDateWithDots(slotProps.data.createdAt) }}
          </span>
        </template>
      </Column>

      <Column
        field="startTime"
        :header="$t('start')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
        sortable
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <strong> {{ $dayjs(slotProps.data[slotProps.column.field]).format('HH:mm') }}</strong>
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="stopTime"
        :header="$t('end')"
        :styles="{ width: '8%' }"
        headerClass="center"
        className="center"
        sortable
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <strong v-if="slotProps.data[slotProps.column.field]">
            {{ $dayjs(slotProps.data[slotProps.column.field]).format('HH:mm') }}</strong
          >
          <strong v-else>
            <HollowDotsSpinner :animation-duration="6000" size="10" color="#495057" />
          </strong>
        </template>

        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data[slotProps.column.field]"
            :showTime="true"
            :manualInput="true"
            :stepMinute="15"
          >
          </Calendar>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>
      <Column field="duration" :header="$t('duration')" sortable :headerStyle="{ width: '100px' }">
        <template #body="slotProps">
          <span v-if="slotProps.data.duration">
            {{ formatDuration(slotProps.data.duration) }}
          </span>
        </template>
      </Column>

      <Column
        field="timeTrackingState"
        :header="$t('state')"
        :styles="{ width: '7%' }"
        sortable
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <b-badge :variant="getTimeTrackingStateColor(slotProps.data.timeTrackingState)">
            {{ $t(slotProps.data.timeTrackingState) }}
          </b-badge>
        </template>
        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('TimeTrackingState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getTimeTrackingStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getTimeTrackingStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.timeTrackingStates"
            :options="timeTrackingStates"
            @input="onTimeTrackingStateFilter"
            optionLabel="label"
            :placeholder="$t('state')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          >
            <template #option="slotProps">
              <b-badge :variant="getTimeTrackingStateColor(slotProps.option.value)">
                {{ slotProps.option.label }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getTimeTrackingStateColor(slotProps.value)">
                {{ slotProps.label }}
              </b-badge>
            </template>
          </MultiSelect>
        </template>
      </Column>

      <Column field="user" :header="$t('employee')" sortable :showFilterMenu="false">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.user.firstname }}
            {{ slotProps.data.user.lastname }}
          </div>
        </template>
        <template #filter>
          <InputText
            :value="tableState.customFilters.timeTrackingUserSearch.value"
            @input="onUserFilter"
          />
        </template>
      </Column>

      <Column field="project" :header="$t('customer')" sortable :showFilterMenu="false">
        <template #body="slotProps">
          <div v-if="slotProps.data && slotProps.data.project && slotProps.data.project.customer">
            {{ slotProps.data.project.customer.firstname || '' }}
            {{ slotProps.data.project.customer.lastname || '' }}
            <template v-if="slotProps.data.project.customer.street">
              , {{ slotProps.data.project.customer.street }}
            </template>
            <template v-if="slotProps.data.project.customer.streetNumber">
              , {{ slotProps.data.project.customer.streetNumber }}
            </template>
            <template v-if="slotProps.data.project.customer.streetNumberSuffix">
              , {{ slotProps.data.project.customer.streetNumberSuffix }}
            </template>
            <template v-if="slotProps.data.project.customer.zip">
              , {{ slotProps.data.project.customer.zip }}
            </template>
            <template v-if="slotProps.data.project.customer.city">
              {{ slotProps.data.project.customer.city }}
            </template>
          </div>
          <div v-else></div>
        </template>
        <template #filter>
          <InputText
            :value="tableState.customFilters.timeTrackingCustomerSearch.value"
            @input="onCustomerFilter"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import { FilterMatchMode } from 'primevue/api';
import { getTimeTrackingStateColor } from '@/helpers/colors';
import ButtonWait from '@/components/ButtonWait.vue';

import { yesNo } from '@/helpers/enums';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import { HollowDotsSpinner } from 'epic-spinners';

export default {
  name: 'TimeTrackingTable',
  components: {
    DataTable,
    Column,
    Calendar,
    Dropdown,
    MultiSelect,
    ButtonWait,
    InputText,
    HollowDotsSpinner,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      defaultFilters: {
        projectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        createdAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },

        startTime: {
          value: this.$dayjs()
            .startOf('month')
            .toDate(),
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        stopTime: {
          value: this.$dayjs()
            .endOf('month')
            .toDate(),
          matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        },
      },
      defaultCustomFilters: {
        timeTrackingStates: [],
        timeTrackingCustomerSearch: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        timeTrackingUserSearch: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },

      tableState: {
        filterName: 'timeTrackings-table-filters',
        customFilterName: 'timeTrackings-custom-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'createdAt',
        sortOrder: 0,
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'getTimeTrackings',
      'getEmployees',
      'getEnumValues',
      'getTimeTrackingCount',
      'getTimeTrackingTotalHours',
      'isLoading',
    ]),
    getTimeTrackingTotalHours() {
      return this.timeTrackings.reduce((total, tracking) => {
        return total + tracking.duration;
      }, 0);
    },
    timeTrackings() {
      return this.getTimeTrackings;
    },

    isProjectContext() {
      return this.project;
    },
    timeTrackingStates() {
      if (!this.getEnumValues('TimeTrackingState')) {
        return [];
      }
      return this.getEnumValues('TimeTrackingState').map((timeTrackingState) => {
        return { value: timeTrackingState, label: this.$t(timeTrackingState) };
      });
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },

    filterName() {
      return 'timeTrackings-table-filters';
    },

    customFilterName() {
      return 'timeTrackings-custom-table-filters';
    },
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchUserAttachmentUrl',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'fetchEmployees',
      'fetchTimeTracking',
      'updateTimeTracking',
      'fetchTimeTracking',
    ]),
    ...mapActions('auth', ['buildUserTimeTrackingExport', 'refreshUser']),

    async startTimeTrackingExportBuild() {
      if (this.getCurrentUser) {
        const userId = this.getCurrentUser.id;
        const totalRecords = this.getTimeTrackingCount;
        const page = 0;
        const pageSize = totalRecords > 0 ? totalRecords : this.tableState.pagination.rowsPerPage;
        const sortField = this.tableState.sortField;
        const sortOrder = this.tableState.sortOrder;
        const filters = {
          ...this.tableState.filters,
          ...this.tableState.customFilters,
        };

        await this.buildUserTimeTrackingExport({
          userId,
          page,
          pageSize,
          sortField,
          sortOrder,
          filters,
        });
      }
    },

    async fetchExportBuildState() {
      await this.refreshUser();

      if (this.getCurrentUser.timeTrackingExportBuildState === 'FINISHED') {
        this.downloadTimeTrackingExport('timeTrackingExportAttachment');
      }
      return this.getCurrentUser.timeTrackingExportBuildState;
    },

    async downloadTimeTrackingExport(attachmentType) {
      try {
        const url = await this.fetchUserAttachmentUrl({
          userId: this.getCurrentUser.id,
          attachmentType: attachmentType,
        });
        window.open(url);
      } catch (error) {
        console.error('Error fetching attachment URL:', error);
      }
    },
    async loadTimeTrackingData() {
      try {
        await this.fetchTimeTracking({
          page: this.pageOffset,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
          filters: {
            ...this.tableState.filters,
            ...this.tableState.customFilters,
            timeTrackingStates: this.tableState.customFilters.timeTrackingStates,
          },
        });
      } catch (error) {
        console.error('Fehler beim Laden der Zeitverfolgungsdaten:', error);
      }
    },
    formatDateWithDots(datetime) {
      const dateObj = new Date(datetime);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      return `${day}.${month}.${year}`;
    },
    async save(timeTracking) {
      if (this.isProjectContext) {
        timeTracking.project = this.project;
      }

      if (timeTracking.number === '_new') {
        await this.createTimeTracking(timeTracking);
      } else {
        console.log(timeTracking);
        await this.updateTimeTracking({
          timeTracking,
        });
      }
    },
    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;

      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 20;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },

    async onFilter(event) {
      console.log('Filter event triggered', event);

      const filters = {
        projectNumber: this.tableState.filters.projectNumber?.value || null,
        timeTrackingCustomerSearch:
          this.tableState.customFilters.timeTrackingCustomerSearch?.value || null,
        timeTrackingUserSearch: this.tableState.customFilters.timeTrackingUserSearch?.value || null,
      };

      Object.keys(filters).forEach((key) => {
        if (filters[key] === null) {
          delete filters[key];
        }
      });

      await this.loadTimeTrackingData(filters);
    },
    /**
     * Funktion zum Filtern des Zeitverfolgungsstatus.
     * @param {Array} value - Die ausgewählten Statuswerte, nach denen gefiltert werden soll.
     */
    async onTimeTrackingStateFilter(value) {
      this.tableState.customFilters.timeTrackingStates = this.timeTrackingStates.filter(
        (timeTrackingState) => value.includes(timeTrackingState)
      );

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadTimeTrackingData();
    },
    async onCustomerFilter(value) {
      this.tableState.customFilters.timeTrackingCustomerSearch.value = value;

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadTimeTrackingData();
    },

    async onUserFilter(value) {
      this.tableState.customFilters.timeTrackingUserSearch.value = value;

      localStorage.setItem(
        this.tableState.customFilterName,
        JSON.stringify(this.tableState.customFilters)
      );

      await this.loadTimeTrackingData();
    },

    async onStateRestore(event) {
      const customFiltersFromStorage = JSON.parse(
        localStorage.getItem(this.tableState.customFilterName)
      );
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;

      this.setupFilters();
    },

    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    },

    /**
     * send task data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;

      data[field] = newValue;

      this.save(data);
    },
    async onSelectState(row) {
      const { data } = row;
      this.save(data);
    },
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      this.loadTimeTrackingData();
    },

    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTimeTrackingData();
    },
    getTimeTrackingStateColor,
  },
  async onPage(event) {
    this.tableState.pagination.page = event.page;
    this.tableState.pagination.rowsPerPage = event.rows;
    await this.loadTimeTrackingData();
  },

  async onSort(event) {
    this.tableState.sortField = event.sortField;
    this.tableState.sortOrder = event.sortOrder;
    await this.loadTimeTrackingData();
  },

  async created() {
    this.setupFilters();
  },

  async mounted() {
    this.filteredEmployees = this.getEmployees;
    await this.fetchEnumValues('TimeTrackingState');

    await this.loadTimeTrackingData();
  },
};
</script>

<style scoped></style>
